import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const query = graphql`
  {
    allStrapiBlog(sort: {fields: date, order: DESC}, limit: 2) {
      nodes {
        date(formatString: "MMMM Do, YYYY")
        image {
            localFile {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
        title
        slug
      }
    }
  }
`

const BlogFooter = () => {
    const data = useStaticQuery(query)
    const {
        allStrapiBlog: { nodes: blogs },
    } = data
    const image0 = getImage(blogs[0].image.localFile)
    const image1 = getImage(blogs[1].image.localFile)
    return (
        <div className="tracer-post-navigation">
            <div className="prev-link-wrapper">
                <div className="info-prev-link-wrapper">
                    <Link to={`/blog/${blogs[0].slug}`}>
                        <span className="image-prev">
                        <GatsbyImage image={image0}
                                                alt=""
                                                formats={["AUTO", "WEBP", "AVIF"]} />
                            <span className="post-nav-title">Read More</span>
                        </span>

                        <span className="prev-link-info-wrapper">
                            <span className="prev-title">{blogs[0].title}</span>
                            <span className="meta-wrapper">
                                <span className="date-post">{blogs[0].date}</span>
                            </span>
                        </span>
                    </Link>
                </div>
            </div>

            <div className="next-link-wrapper">
                <div className="info-next-link-wrapper">
                    <Link to={`/blog/${blogs[1].slug}`}>
                        <span className="next-link-info-wrapper">
                            <span className="next-title">{blogs[1].title}</span>
                            <span className="meta-wrapper">
                                <span className="date-post">{blogs[0].date}</span>
                            </span>
                        </span>

                        <span className="image-next">
                        <GatsbyImage image={image1}
                                                alt=""
                                                formats={["AUTO", "WEBP", "AVIF"]} />
                            <span className="post-nav-title">Read More</span>
                        </span>
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default BlogFooter