import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "/src/components/App/Layout"
import Navbar from "/src/components/App/Navbar"
import PageBanner from '/src/components/Common/PageBanner'
import Footer from "/src/components/App/Footer"
import BlogSidebar from '/src/components/BlogContent/BlogSidebar'
import BlogFooter from '/src/components/BlogContent/BlogFooter'
import ReactMarkdown from 'react-markdown'

const BlogDetailsContent = ({data}) => {
    const image = getImage(data.blog.image.localFile)
    return (
        <Layout>
            <Navbar />
            <PageBanner
            />
            <section className="blog-details-area ptb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <GatsbyImage image={image}
                                        alt=""
                                        formats={["AUTO", "WEBP", "AVIF"]}
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <i className='bx bx-group'></i>
                                                <span>Read Time</span>
                                                {data.blog.read_time} min
                                            </li>
                                            <li>
                                                <i className='bx bx-calendar'></i>
                                                <span>Posted On</span>
                                                {data.blog.date}
                                            </li>
                                        </ul>
                                    </div>

                                    <h2>{data.blog.title}</h2>
                                    <article>
                                        <ReactMarkdown>{data.blog.content}</ReactMarkdown>
                                    </article>
                                </div>

                                <div className="article-footer">
                                    <div className="article-tags">
                                    </div>

                                    <div className="article-share">
                                        <ul className="social">
                                            <li><span>Share:</span></li>
                                            <li>
                                                <a className="facebook" href={`https://www.facebook.com/sharer/sharer.php?u=tafoma.com/blog/${data.blog.slug}`}>
                                                    <i className='bx bxl-facebook'></i>
                                                </a>
                                                {/* <Link to="#" className="facebook">
                                                </Link> */}
                                            </li>
                                            <li>
                                                <a className="twitter" href={`https://twitter.com/intent/tweet?text=tafoma.com/blog/${data.blog.slug}`}>
                                                    <i className='bx bxl-twitter'></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="linkedin" href={`https://www.linkedin.com/shareArticle?mini=true&url=tafoma.com/blog/${data.blog.slug}&title=${data.blog.title}`}>
                                                    <i className='bx bxl-linkedin'></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <BlogFooter />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default BlogDetailsContent

export const query = graphql`
  query GetSingleBlog($slug: String) {
      blog: strapiBlog(slug: { eq: $slug }) {
          content
          title
          read_time
          slug
          date(formatString: "MMMM Do, YYYY")
          image {
            localFile {
                childImageSharp {
                    gatsbyImageData
                }
            }
          }
        }
    }
`