import React from 'react'
import { Link, graphql, useStaticQuery, } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"



const query = graphql`
  {
    allStrapiBlog(sort: {fields: date, order: ASC}, limit: 3) {
      nodes {
        date(formatString: "MMMM Do, YYYY")
        image {
          localFile {
              childImageSharp {
                  gatsbyImageData
              }
          }
      }
        title
        slug
      }
    }
  }
`
const BlogSidebar = () => {
    const data = useStaticQuery(query)
    const {
        allStrapiBlog: { nodes: blogs },
    } = data
    return (
        <div className="widget-area">
            <div className="widget widget_tracer_posts_thumb">
                <h3 className="widget-title">Popular Posts</h3>

                {blogs.map((blog) => {
                        const image = getImage(blog.image.localFile)
                        return (
                            <article className="item">
                    <Link to={`/blog/${blog.slug}`} className="thumb">
                        <span className="fullimage cover" role="img"> <GatsbyImage image={image}
                                                alt=""
                                                formats={["AUTO", "WEBP", "AVIF"]} /></span>
                    </Link>
                    <div className="info">
                        <span>{blog.date}</span>
                        <h4 className="title usmall">
                            <Link to={`/blog/${blog.slug}`}>
                                {blog.title}
                            </Link>
                        </h4>
                    </div>

                    <div className="clear"></div>
                </article>
                        )
                })}
            </div>
        </div>
    )
}

export default BlogSidebar